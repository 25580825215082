<template>
  <span class="float-right text-xs ion-text-white pr-4 fadein-900" v-if="showLogOut" @click="logOut">Log Out</span>
  <div :class="props.hideLogo ? 'invisible' : 'fadein-900'">
    <ion-img :src="logoSrc" :class="logoClass" class="mx-auto "></ion-img>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useCustomerStore } from '@/stores';
import { asyncComputed } from '@vueuse/core';
import { useSecureStorage } from '@/composables/secureStorage';
import { storeToRefs } from 'pinia';
import { useBrowser } from '@/composables/browser';
import axios from 'axios';
const customerStore = useCustomerStore();
const { updateAuth, updateLoggedOut } = customerStore;
const { loggedOut, deviceID, deviceName, deviceModel, pushNotificationToken, environment, authToken } = storeToRefs(customerStore);
const props = defineProps(['color', 'start', 'hideLogo', 'hideLogOut']);
const { urls } = useBrowser();
const logoSrc = computed(() => props.color && props.color == 'purple' ? '/assets/logos/logo-purple.svg' : '/assets/logos/logo-white.svg');
const logoClass = computed(() => props.color && props.color == 'purple' ? 'logo-purple' : 'logo-white mt-10 mb-4 ion-img-logo');
const { keys, getStorage, clearAllStorage } = useSecureStorage();
const proStorage = asyncComputed(async () => await getStorage({ key: keys.pro }), null);
const showLogOut = ref(false);
const status = ref('');

const payload = computed(() => ({
  device_id: deviceID.value,
  device_name: deviceName.value,
  device_model: deviceModel.value,
  environment: environment.value,
  push_token: pushNotificationToken.value
}));

const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};

const signOut = () => {
  updateAuth(false);
  updateLoggedOut(true);
  clearAllStorage();
};

const logOut = () => {
  if (Capacitor.isNativePlatform()) {
    axios.post(urls.apiLogOut, payload.value, headers)
      .then(res => {
        if (res.status == 200) {
          status.value = 'logged out';
          signOut();
        }
      })
      .catch(err => {
        if (err) {
          status.value = 'error';
          signOut();
        }
      })
  }
};

const checkToken = () => {
  if (Capacitor.isNativePlatform()) {
    setTimeout(() => {
      showLogOut.value = (proStorage.value && { ...JSON.parse(proStorage.value.value) }.token && props.start && !loggedOut.value && props.hideLogOut) ? true : false;
    }, 500);
  }
};

watch(loggedOut, () => {
  setTimeout(() => {
    checkToken();
  }, 500);
}, { immediate: true });

onMounted(() => {
  setTimeout(() => {
    checkToken();
  }, 500);
})
</script>

<style scoped>
.logo-purple {
  width: auto;
  height: 39px;
}

.logo-white {
  width: 167px;
  height: 66px;
}

.ios .ion-img-logo {
  margin-top: 4rem;
}
</style>