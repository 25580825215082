import { defineStore } from "pinia";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import axios from "axios";
import router from "@/router";
import { applyDeviceParamsToUrl } from "@/composables/adjust";
import { useBrowser } from "@/composables/browser";

export const useCustomerStore = defineStore("customer", {
  state: () => ({
    profile: {},
    email: "",
    name: "",
    newCustomer: false,
    authenticated: false,
    loggedOut: false,
    startCheckIfQualify: false,
    startTrackCreditScore: false,
    otpEnabled: false,
    bioEnabled: false,
    deviceID: "",
    deviceName: "",
    deviceModel: "",
    pushNotificationToken: "",
    sessionID: "",
    environment: "",
    authToken: "",
    emailForgotPassword: "",
    emailForgotPasswordCompleted: false,
    tokenForgotPassword: "",
    currentVersion: "3.1.2", // for api call param (maintain manually for desktop)
    installedVersion: null, // for app update popup condition check
    availableVersion: null, // for app update popup condition check
    appUpdateClosed: false,
    appUpdateForced: false,
    locEligible: null,
    creditScoreEligible: null,
    creditScoreEnrolled: null,
    creditScoreConsent: null,
    creditScoreErrMsg: null,
    creditScore: null,
    idCheckConsent: null,
    idCheckErrTitle: null,
    idCheckErrMsg: null,
    idCheckErrRetry: null,
    idCheckErrRetryCount: 0,
    financeFundamentals: {},
    financeFundamentalsEnrolled: null,
    financeFundamentalsCompleted: null,
    financeFundamentalsCompletedModules: null,
    financeFundamentalsTotalModules: null,
    existingAccount: false,
    existingAccountMsg: null,
    existingCustomer: null,
    applyUrl: null,
  }),

  getters: {
    getProfile(state) {
      return state.profile;
    },

    getEmail(state) {
      return state.email;
    },

    getName(state) {
      return state.name;
    },

    getDeviceID(state) {
      return state.deviceID;
    },

    getDeviceName(state) {
      return state.deviceName;
    },

    getdeviceModel(state) {
      return state.deviceModel;
    },

    checkAuth(state) {
      return state.authenticated;
    },

    checkOtp(state) {
      return state.otpEnabled;
    },

    checkBio(state) {
      return state.bioEnabled;
    },

    getCurrentVersion(state) {
      return state.currentVersion;
    },

    getInstalledVersion(state) {
      return state.installedVersion;
    },

    getAvailableVersion(state) {
      return state.availableVersion;
    },

    getAppUpdateClosed(state) {
      return state.appUpdateClosed;
    },

    getLocEligible(state) {
      return state.locEligible;
    },

    getCreditScoreEligible(state) {
      return state.creditScoreEligible;
    },

    getCreditScoreEnrolled(state) {
      return state.creditScoreEnrolled;
    },

    getCreditScoreConsent(state) {
      return state.creditScoreConsent;
    },

    getCreditScoreErrMsg(state) {
      return state.creditScoreErrMsg;
    },

    getCreditScore(state) {
      return state.creditScore;
    },

    hasCurrentCreditScore() {
      let currentScore = this.checkValue(this.creditScore, "current_score");
      return currentScore !== null;
    },

    getIdCheckConsent(state) {
      return state.idCheckConsent;
    },

    getIdCheckErrTitle(state) {
      return state.idCheckErrTitle;
    },

    getIdCheckErrMsg(state) {
      return state.idCheckErrMsg;
    },

    getCustomerParams(state) {
      return (
        "?environment=" +
        state.environment +
        "&app_version=" +
        state.currentVersion +
        "&push_token=" +
        state.pushNotificationToken +
        "&device_id=" +
        state.deviceID +
        "&device_name=" +
        state.deviceName +
        "&device_model=" +
        state.deviceModel
      );
    },
  },

  actions: {
    setProfile(val) {
      this.profile = val;
      this.setLocEligible();
      this.setCreditScoreEligible();
      this.setCreditScoreEnrolled();
      this.setCreditScore();
      this.setFinanceFundamentalsEnrolled();
      this.setFinanceFundamentalsCompleted();
      this.setFinanceFundamentalsCompletedModules();
      this.setFinanceFundamentalsTotalModules();
      this.setExistingCustomer();
      this.setApplyUrl();
    },

    setEmail(val) {
      this.email = val;
    },

    setName(val) {
      this.name = val;
    },

    updateAuth(val) {
      this.authenticated = val;
    },

    updateLoggedOut(val) {
      this.loggedOut = val;
    },

    setStartCheckIfQualify(val) {
      this.startCheckIfQualify = val;
    },

    setStartTrackCreditScore(val) {
      this.startTrackCreditScore = val;
    },

    updateOtp(val) {
      this.otpEnabled = val;
    },

    updateBio(val) {
      this.bioEnabled = val;
    },

    updateNewCustomer(val) {
      this.newCustomer = val;
    },

    clearProfile() {
      this.profile = {};
    },

    clearName() {
      this.name = "";
    },

    clearEmail() {
      this.email = "";
    },

    clearStartSelection() {
      this.startCheckIfQualify = false;
      this.startTrackCreditScore = false;
      this.idCheckErrRetryCount = 0;
    },

    async setDeviceInfo() {
      this.deviceID = (await Device.getId()).identifier
        ? (await Device.getId()).identifier
        : "";
      this.deviceName = (await Device.getInfo()).name
        ? (await Device.getInfo()).name
        : "";
      this.deviceModel = (await Device.getInfo()).model
        ? (await Device.getInfo()).model
        : "";
    },

    setPushNotificationToken(val) {
      this.pushNotificationToken = val;
    },

    setSessionID(val) {
      this.sessionID = val;
    },

    setEnvironment(val) {
      this.environment = val;
    },

    setAuthToken(val) {
      this.authToken = val;
    },

    setEmailForgotPassword(val) {
      this.emailForgotPassword = val;
    },

    setEmailForgotPasswordCompleted(val) {
      this.emailForgotPasswordCompleted = val;
    },

    setTokenForgotPassword(val) {
      this.tokenForgotPassword = val;
    },

    setCurrentVersion(val) {
      this.currentVersion = val;
    },

    setInstalledVersion(val) {
      this.installedVersion = val;
    },

    setAvailableVersion(val) {
      this.availableVersion = val;
    },

    setAppUpdateClosed(val) {
      this.appUpdateClosed = val;
    },

    setAppUpdateForced(val) {
      this.appUpdateForced = val;
    },

    checkValue(obj, val) {
      return Object.keys(obj).includes(val) ? obj[val] : null;
    },

    setLocEligible() {
      this.locEligible = this.checkValue(
        this.profile,
        "eligible_for_line_of_credit"
      );
    },

    setCreditScoreEligible() {
      this.creditScoreEligible = this.checkValue(
        this.profile,
        "eligible_to_check_credit_score"
      );
    },

    setCreditScoreEnrolled() {
      this.creditScoreEnrolled = this.checkValue(
        this.profile,
        "enrolled_to_check_credit_score"
      );
    },

    setCreditScoreConsent(val) {
      this.creditScoreConsent = val;
    },

    setCreditScoreErrMsg(val) {
      this.creditScoreErrMsg = val;
    },

    setCreditScore() {
      this.creditScore = this.checkValue(this.profile, "credit_score");
    },

    setIdCheckConsent(val) {
      this.idCheckConsent = val;
    },

    setIdCheckErrTitle(val) {
      this.idCheckErrTitle = val;
    },

    setIdCheckErrMsg(val) {
      this.idCheckErrMsg = val;
    },

    setIdCheckErrRetry(val) {
      this.idCheckErrRetry = val;
    },

    setIdCheckErrRetryCount() {
      this.idCheckErrRetryCount += 1;
    },

    resetIdCheckErrRetryCount() {
      this.idCheckErrRetryCount = 0;
    },

    setCreditScoreChange(change) {
      if (change > 0) {
        return {
          label: "+" + change + "pts",
          style: "green",
        };
      } else if (change < 0) {
        return {
          label: change + "pts",
          style: "red",
        };
      } else {
        return {
          label: "-",
          style: "brand",
        };
      }
    },

    getFinanceFundamentals(url, token) {
      setTimeout(() => {
        axios
          .get(url, {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          })
          .then((res) => {
            this.setFinanceFundamentals(res.data);
          })
          .catch((err) => console.log(err));
      }, 1000);
    },

    setFinanceFundamentals(val) {
      this.financeFundamentals = val;
    },

    setFinanceFundamentalsEnrolled() {
      this.financeFundamentalsEnrolled = this.checkValue(
        this.profile,
        "finance_fundamentals_enrolled"
      );
    },

    setFinanceFundamentalsCompleted() {
      this.financeFundamentalsCompleted = this.checkValue(
        this.profile,
        "finance_fundamentals_completed"
      );
    },

    setFinanceFundamentalsCompletedModules() {
      this.financeFundamentalsCompletedModules = this.checkValue(
        this.profile,
        "finance_fundamentals_completed_modules"
      );
    },

    setFinanceFundamentalsTotalModules() {
      this.financeFundamentalsTotalModules = this.checkValue(
        this.profile,
        "finance_fundamentals_total_modules"
      );
    },

    setExistingAccount(val) {
      this.existingAccount = val;
    },

    setExistingAccountMsg(val) {
      this.existingAccountMsg = val;
    },

    setExistingCustomer() {
      this.existingCustomer = this.checkValue(
        this.profile,
        "existing_customer"
      );
    },

    checkExistingAccount(status, msg, callback) {
      let existing =
        status == 422 &&
        msg.includes("looks like you already have an account with us");
      if (existing) {
        this.setExistingAccount(true);
        this.setExistingAccountMsg(msg);
        router.push("/register-existing-account");
        callback();
      }
    },

    setApplyUrl() {
      if (this.authenticated) {
        const { urls } = useBrowser();
        axios
          .post(
            urls.apiCustomerApply,
            {
              device_id: this.deviceID,
              environment: this.environment,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.authToken,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              const source =
                res.data.type == "repeat" ? "APPLYEXISTING" : "APPLYNEW";
              this.applyUrl = applyDeviceParamsToUrl(
                res.data.apply_url,
                source
              );
            } else {
              this.applyUrl = applyDeviceParamsToUrl(
                urls.createAccount,
                "APPLYNEW"
              );
            }
          })
          .catch((err) => {
            if (err) {
              this.applyUrl = applyDeviceParamsToUrl(
                urls.createAccount,
                "APPLYNEW"
              );
            }
          });
      } else {
        this.applyUrl = applyDeviceParamsToUrl(urls.createAccount, "APPLYNEW");
      }
    },
    openApplyUrl() {
      const { openBrowser, browserCheckSafari } = useBrowser();
      if (!Capacitor.isNativePlatform() && browserCheckSafari()) {
        window.open(this.applyUrl);
      } else {
        openBrowser(this.applyUrl);
      }
    },
  },
});
