<template>
  <ion-page :class="bgClass">
    <ion-content :fullscreen="true" forceOverscroll="false">
      <app-loader v-if="loading"></app-loader>
      <div v-else>
        <app-logo start="true" :hide-logo="hideLogo" :hide-log-out="hideLogOut"></app-logo>
        <start-swiper @activeSlide="activeSlide"></start-swiper>
        <start-button @logInWith="logInWith"></start-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import AppLoader from '@/components/start/AppLoader.vue';
import StartSwiper from '@/components/start/StartSwiper.vue';
import StartButton from '@/components/start/StartButton.vue';
import AppLogo from '@/components/start/AppLogo.vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { storeToRefs } from 'pinia';
import { useConfigStore, useCustomerStore, useLoansStore, useNotificationsStore, useRegisterStore } from '@/stores';
const configStore = useConfigStore();
const customerStore = useCustomerStore();
const loansStore = useLoansStore();
const notificationsStore = useNotificationsStore();
const registerStore = useRegisterStore();
const { loading } = storeToRefs(configStore);
const bgClass = computed(() => loading.value ? '' : 'ion-page-start');
const { setLoading, clearConfig } = configStore;
const { clearProfile, clearName, clearEmail, clearStartSelection } = customerStore;
const { clearLoans } = loansStore;
const { clearNotifications } = notificationsStore;
const { clearPrefill } = registerStore;
const hideLogo = ref(false);
const hideLogOut = ref(null);
const resetStates = () => {
  clearPrefill();
  clearConfig();
  clearProfile();
  clearName();
  clearEmail();
  clearStartSelection();
  clearLoans();
  clearNotifications();
}
const activeSlide = (id) => {
  hideLogo.value = id == 0 ? true : false;
}
const logInWith = (e) => {
  hideLogOut.value = e;
}
onMounted(() => {
  SplashScreen.hide();
  resetStates();
  setTimeout(() => {
    setLoading(false);
  }, 3000);

});
</script>