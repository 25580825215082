<template>
  <ion-app :class="screenHeight">
    <ion-router-outlet :key="routeKey" v-if="forceUpdate"></ion-router-outlet>
    <ion-router-outlet v-else></ion-router-outlet>
  </ion-app>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { isPlatform } from '@ionic/vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useBrowser } from './composables/browser';
import { useCustomerStore, useConfigStore } from '@/stores';
import { registerAdjust, setPushToken } from './composables/adjust';
import { subscribePushNotificationRegistration, registerPushNotifications } from './composables/pushNotifications';
import { useUpdate } from '@/composables/update';
const { getInstalledAppVersion, getAvailableAppVersion } = useUpdate();
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const { setDeviceInfo, setCurrentVersion, setInstalledVersion, setAvailableVersion, setPushNotificationToken } = customerStore;
const { checkScreenSizeSmall } = configStore;
const { currentVersion } = storeToRefs(customerStore);
const { screenSizeSmall } = storeToRefs(configStore);
const { backButton } = useBrowser();
const route = useRoute();
const routeKey = computed(() => route.path);
const forceUpdate = computed(() => !routeKey.value.includes('/tabs/'));
const screenHeight = computed(() => screenSizeSmall.value ? 'screen-height-sm' : '');

onMounted(async () => {
  setDeviceInfo();
  checkScreenSizeSmall();
  if (Capacitor.isNativePlatform()) {
    if (isPlatform('android')) {
      backButton();
    }
    registerAdjust();
    subscribePushNotificationRegistration(token => {
      setPushNotificationToken(token.value); // for push notification
      setPushToken(token.value); // for adjust
    });
    registerPushNotifications();
    setCurrentVersion(currentVersion.value); // use customer.js value for all platform
    setInstalledVersion(await getInstalledAppVersion());
    setAvailableVersion(await getAvailableAppVersion());
  } else {
    // for desktop
    setCurrentVersion(currentVersion.value);
    setInstalledVersion(currentVersion.value);
    setAvailableVersion(currentVersion.value);
  }
});
</script>