<template>
  <swiper :breakpoints="breakpoints" :modules="modules" :slides-per-view="1" :pagination="pagination"
    :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange" class="pb-3 swiper-start fadein">
    <swiper-slide v-for="slide in slides" :key="slide.id">
      <div class="fadein-900 text-center">
        <h2 class="font-bold px-3"
          :class="slide.id == 1 ? `ion-text-yellow italic ${styleTitleLogo}` : `text-white ${styleTitleImgs}`"
          v-html="slide.title">
        </h2>
        <div class="fadein-900">
          <img loading="lazy" :src="slide.image" class="mx-auto slider"
            :class="slide.id == 1 ? styleLogo : styleImgs" />
          <img loading="lazy" :src="slide.loading" class="hidden" v-if="slide.loading" />
        </div>
        <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        <p class="ion-text-white px-3 pb-1" :class="styleText" v-html="slide.subtitle"></p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useConfigStore } from '@/stores';
import { storeToRefs } from 'pinia';
import 'swiper/css';
import 'swiper/css/pagination';
const configStore = useConfigStore();
const { screenSizeSmall } = storeToRefs(configStore);
const styleTitleLogo = computed(() => screenSizeSmall.value ? 'text-[40px] mb-[20px]' : 'text-[57px] mb-[40px] pt-6');
const styleTitleImgs = computed(() => screenSizeSmall.value ? 'text-[24px]' : 'text-[32px] pt-6');
const styleLogo = computed(() => screenSizeSmall.value ? 'h-[170px]' : 'h-[220px]');
const styleImgs = computed(() => screenSizeSmall.value ? 'h-[120px] mt-1' : 'h-[170px] mt-2');
const styleText = computed(() => screenSizeSmall.value ? 'text-[12px] pt-1' : 'text-[14px] pt-3');
const emit = defineEmits(['activeSlide']);
const modules = [Pagination];
const breakpoints = {
  640: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 1,
  },
  1280: {
    slidesPerView: 1,
  },
};
const pagination = {
  clickable: true
};
const classTitle = 'text-[#8232ff] bg-[#FEE113] inline-block my-1 py-1 px-3 rounded italic';
const slides = ref([
  {
    id: 1,
    title: 'Welcome to',
    subtitle: '',
    image: '/assets/carousel/slide-1.png',
    loading: '/assets/carousel/slide-2.gif',
  },
  {
    id: 2,
    title: `Fast<br/><span class="${classTitle}">Affordable Loans</span><br/> up to $25,000`,
    subtitle: 'Check if you qualify without impacting your credit score',
    image: '/assets/carousel/slide-2.gif',
    loading: '/assets/carousel/slide-3.gif',
  },
  {
    id: 3,
    title: `Track your<br/><span class="${classTitle}">Credit Score</span><br/>for Free`,
    subtitle: 'Track your credit score and understand how to improve<br/>your credit profile with our finance fundamentals course!',
    image: '/assets/carousel/slide-3.gif',
    loading: '/assets/carousel/slide-4.gif',
  },
  {
    id: 4,
    title: `Build your<br/><span class="${classTitle}">Credit Score</span><br/>Faster`,
    subtitle: 'With Comprehensive Credit Reporting (CCR), you could<br/>improve your credit score with timely repayments!',
    image: '/assets/carousel/slide-4.gif',
    loading: '/assets/carousel/slide-1.png',
  }
]);
const onSwiper = (swiper) => {
  emit('activeSlide', swiper.activeIndex);
};

const onSlideChange = (swiper) => {
  emit('activeSlide', swiper.activeIndex);
}
</script>

<style scoped>
.slider {
  min-height: 120px;
}

.swiper-lazy-preloader {
  opacity: 0.6;
}
</style>